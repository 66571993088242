import React from "react";
import loadable from "@loadable/component";

const ArticlesQuery = loadable(() => import("@dynamicQueries/ArticlesQuery"));
const PageQuery = loadable(() => import("@dynamicQueries/PageQuery"));
const JobsQuery = loadable(() => import("@dynamicQueries/JobsQuery"));
const PeopleQuery = loadable(() => import("@dynamicQueries/PeopleQuery"));

const Preview = ({ location }) => {
  // get first segment
  const { pathname } = location;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const type = urlParams.get("type");
  const props = {
    location,
    pageContext: {
      uri: pathname,
    },
  };

  // set up queries here for preview
  switch (type) {
    case "articles":
      return <ArticlesQuery preview {...props} />;
    case "page":
      return <PageQuery preview {...props} />;
    case "jobs":
      return <JobsQuery preview {...props} />;
    case "people":
      return <PeopleQuery preview {...props} />;
    default:
      return <div {...props} />;
  }
};

export default Preview;
